<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
         <div class="text">
          <div class="title">Welcome to sinDAO, fren</div>
          <div class="desc">
            The most sinful MemeFi cabal ever existed.
          </div>
          <div class="buttons">
            <a target="_blank" href="https://dexscreener.com/solana/sin1uRe1cMCWR7VPLdZrGrvKs8UvKMsGzhvpJLg4Ld9" class="button red">
              Buy $SIN
            </a>
            <a target="_blank" href="https://app.sindao.org" class="button red">
              Use App
            </a>
          </div>
         </div>
         <img src="./../assets/hero-img.png" class="img"/>
        </div>
        <div class="gif-container">
          <img src="./../assets/pole.gif" class="gif"/>
        </div>
        <div class="ticker-container">
          <div class="ticker">
            <div class="item">
              <div class="title">BUY $SIN. GET RICH FAST!</div>
              <div class="title">BUY $SIN. GET RICH FAST!</div>
              <div class="title">BUY $SIN. GET RICH FAST!</div>
              <div class="title">BUY $SIN. GET RICH FAST!</div>
              <div class="title">BUY $SIN. GET RICH FAST!</div>
              <div class="title">BUY $SIN. GET RICH FAST!</div>
              <div class="title">BUY $SIN. GET RICH FAST!</div>
              <div class="title">BUY $SIN. GET RICH FAST!</div>
              <div class="title">BUY $SIN. GET RICH FAST!</div>
              <div class="title">BUY $SIN. GET RICH FAST!</div>
              <div class="title">BUY $SIN. GET RICH FAST!</div>
              <div class="title">BUY $SIN. GET RICH FAST!</div>
              <div class="title">BUY $SIN. GET RICH FAST!</div>
              <div class="title">BUY $SIN. GET RICH FAST!</div>
              <div class="title">BUY $SIN. GET RICH FAST!</div>
              <div class="title">BUY $SIN. GET RICH FAST!</div>
              <div class="title">BUY $SIN. GET RICH FAST!</div>
            </div>
           
          </div>
        </div>
      </div>
      <div class="section about-section" id="about">
        <div class="wrapper">
          <div class="text">
            <div class="title">About</div>
            <div class="desc">
              Sins drive innovation. Sins drive the markets. Sins drive evolution. Sins are at the core of memetic economy. 
            </div>
            <div class="desc">
              We embrace sins. We inscribe sins. We are brothers in $SIN. Infernal Transmutation awaits your sinful soul!
            </div>
          </div>
        </div>
        <div class="eye-container">
          <img src="./../assets/pyramid.png" class="eye"/>
        </div>
      </div>
      <div class="section how-section" id="memefi">
        <div class="wrapper">
          <div class="title">How does it work?</div>
          <div class="desc">
            $SIN is at the core of the MemeFi economy. You can use $SIN to mint new, unique Sinner NFTs permissionlessly, or burn Sinner NFTs to retrieve that sweet sweet $SIN back.
          </div>
          <div class="desc">
            Every time you burn a Sinner NFT, 66,666,666 $SIN is burnt forever. This demonstrates how deflation works in our system.
          </div>
          <div class="desc">
            The max supply of $SIN is 6,666,666,666,666. NFT mints aren’t hard-capped but are limited by the $SIN supply. Each mint births a new unique Sinner.
          </div>
          <div class="how-block">
            <div class="coin-block">
              <div class="coin">
                <img src="./../assets/coin1.png" class="img"/>
              </div>
              <div class="desc">Deflationary Token</div>
            </div>
            <div class="center-block">
              <div class="burn">
                <div class="desc">
                  Use <br/>
                  666,666,666 $SIN<br/>
                  to mint Sinner NFT
                </div>
              </div>
              <div class="center">
                <div class="title">MemeFi</div>
                <div class="desc">
                  Infernal Transmutation
                </div>
                <div class="desc small">
                  Each time 66,666,666 $SIN will be removed from the supply forever
                </div>
              </div>
              <div class="burn">
                <img src="./../assets/flame.png" class="img"/>
                <div class="desc">
                  Burn  <br/>
                  sinner NFT to get <br/>
                  600,000,000 $SIN
                </div>
              </div>
            </div>
            <div class="coin-block">
              <div class="coin">
                <img src="./../assets/coin2.png" class="img"/>
              </div>
              <div class="desc">Dynamic NFT Collection</div>
            </div>
          </div>
        </div>
      </div>
      <div class="section collection-section">
        <div class="wrapper">
          <div class="title">
            sinDAO is  <br/>a snapshot  <br/>of crypto<br/> sins
          </div>
        </div>
      </div>
      <div class="section think-section" id="think">
        <div class="title">NFT by day. Meme coin by night.</div>
        <div class="wrapper">
          <img src="./../assets/thinkImg.gif" class="img"/>
          <div class="text">
            <div class="text-top">
              <div class="desc">
                The maximum number of sinners will never exceed 9,999, but the collection will always remain vibrant, fresh, and reflect the current narrative. 
              </div>
              <div class="desc">
                No baby sinners, dog sinners, sinner’s land, sinners wif hats (u do this shit), pixel sinners, 3D sinners, sinner beans, and other trash derivatives. 
              </div>
            </div>
            <div class="buttons">
              <a target="_blank" href="https://www.tensor.trade/trade/sindao" class="button red">Tensor</a>
              <a target="_blank" href="https://www.sniper.xyz/collection/sindao" class="button red">Sniper</a>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="section sinners-section">
        <div class="wrapper">
          <div class="title">Sinners NFT</div>
          <div class="list">
            <div class="item" v-for="item in 12" :key="item">
              <img :src="require(`./../assets/sinners/${item}.png`)" class="img"/>
            </div>
          </div>
        </div>
      </div> -->
      <div class="section tokensmics-section" id="tokensmics">
        <div class="wrapper">
          <div class="title">Tokenomics</div>
          <div class="text">
            <div class="text__item">
              <div class="desc small">Ticker: </div>
              <div class="desc big">$SIN</div>
            </div>
            <div class="text__item">
              <div class="desc small">Max Supply:</div>
              <div class="desc big">6,666,666,666,666 $SIN</div>
            </div>
            <div class="text__item">
              <div class="desc big">No taxes</div>
            </div>
            <div class="text__item">
              <div class="desc big">
                <img src="./../assets/flame.png" class="img"/>
                LP tokens are burnt, and contract ownership is renounced
              </div>
            </div>
          </div>
          <div class="chart">
            <div class="chart__item">
              <div class="desc">Genesis NFT</div>
              <div class="title">5.99%</div>
            </div>
            <div class="chart__item">
              <div class="title">84%</div>
              <div class="desc">Liquidity Pool</div>
            </div>
            <div class="chart__item">
              <div class="title">9.99%</div>
              <div class="desc">Marketing and CEX</div>
            </div>
          </div>
        </div>
      </div>
      <div class="section contact-section">
        <div class="wrapper">
          <div class="title">Contract Adress:</div>
          <div class="copy-block">
            <a class="title" href="https://solscan.io/token/sin1uRe1cMCWR7VPLdZrGrvKs8UvKMsGzhvpJLg4Ld9" target="_blank">sin1uRe1cMCWR7VPLdZrGrvKs8UvKMsGzhvpJLg4Ld9</a>
            <img src="./../assets/copy.svg" class="img copy-link-button" @click="copyLink"/>
          </div>
        </div>
      </div>
      <div class="section sin-section">
        <div class="wrapper">
          <div class="title"><span class="red">$SIN</span><br/> at the core of memetic economy</div>
          <div class="list">
            <div class="item">
              <img src="./../assets/sin/1.png" class="img"/>
              <div class="desc">Daly missons <br/>for $SIN</div>
            </div>
            <div class="item">
              <img src="./../assets/sin/2.png" class="img"/>
              <div class="desc">$SIN <br/>Giveaway</div>
            </div>
            <div class="item">
              <img src="./../assets/sin/3.png" class="img"/>
              <div class="desc">$SIN <br/>Leaderboard</div>
            </div>
          </div>
        </div>
      </div>
      <div class="section road-section" id="roadmap">
        <div class="wrapper">
          <div class="title">THE ROAD TO 1 BILLY</div>
          <div class="list">
            <div class="item">
              <div class="num">I</div>
              <img src="./../assets/road/1.png" class="img"/>
              <div class="text">
                <div class="title">GENESIS MINT OF 666 SINNERS</div>
                <div class="desc">Create screens directly in Strato.space or <br/>add your images from Figma. Create screens directly in Strato.space </div>
              </div>
            </div>
            <div class="item">
              <div class="num">II</div>
              <img src="./../assets/road/2.png" class="img"/>
              <div class="text">
                <div class="title">DEPLOY THE $SIN</div>
                <div class="desc">Create screens directly in Strato.space or <br/>add your images from Figma. Create screens directly in Strato.space </div>
              </div>
            </div>
            <div class="item">
              <div class="num">III</div>
              <img src="./../assets/road/3.png" class="img"/>
              <div class="text">
                <div class="title">PENETRATE THE FEED</div>
                <div class="desc">Create screens directly in Strato.space or <br/>add your images from Figma. Create screens directly in Strato.space </div>
              </div>
            </div>
            <div class="item">
              <div class="num">IV</div>
              <img src="./../assets/road/4.png" class="img"/>
              <div class="text">
                <div class="title">ACTIVATE DEFLATIONARY CHAOS</div>
                <div class="desc">Create screens directly in Strato.space or <br/>add your images from Figma. Create screens directly in Strato.space </div>
              </div>
            </div>
            <div class="item">
              <div class="num">V</div>
              <img src="./../assets/road/5.png" class="img"/>
              <div class="text">
                <div class="title">PENETRATE THE SCREENERS</div>
                <div class="desc">Create screens directly in Strato.space or <br/>add your images from Figma. Create screens directly in Strato.space </div>
              </div>
            </div>
            <div class="item">
              <div class="num">VI</div>
              <img src="./../assets/road/6.png" class="img"/>
              <div class="text">
                <div class="title">ACTIVATE DEFLATIONARY CHAOS</div>
                <div class="desc">Create screens directly in Strato.space or <br/>add your images from Figma. Create screens directly in Strato.space </div>
              </div>
            </div>
            <div class="item">
              <div class="num">VII</div>
              <img src="./../assets/road/7.png" class="img"/>
              <div class="text">
                <div class="title">PENETRATE THE SCREENERS</div>
                <div class="desc">Create screens directly in Strato.space or <br/>add your images from Figma. Create screens directly in Strato.space </div>
              </div>
            </div>
            <div class="item">
              <div class="num">VIII</div>
              <img src="./../assets/road/8.png" class="img"/>
              <div class="text">
                <div class="title">ENSCRIBE THE SINS PURGE <br/>THE $SIN REPEAT</div>
                <div class="desc">Create screens directly in Strato.space or <br/>add your images from Figma. Create screens directly in Strato.space </div>
              </div>
            </div>
            <div class="item">
              <div class="num red">IX</div>
            </div>
          </div>
        </div>
      </div>
      <div class="section together-section">
        <div class="wrapper">
          <div class="title big">GOT $SIN?</div>
          <a target="_blank" class="button red" href="https://dexscreener.com/solana/sin1uRe1cMCWR7VPLdZrGrvKs8UvKMsGzhvpJLg4Ld9">Buy $SIN</a>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

import Clipboard from 'clipboard';

export default {
  name: 'Home',
  props: [],
  components: {
  },
  data: function() {
    return {
      link: 'sin1uRe1cMCWR7VPLdZrGrvKs8UvKMsGzhvpJLg4Ld9'
    }
  },
  mounted() {
  },
  methods: {
    scrollToSection(section) {
      this.$emit('scrollToSection', section)
    },
    copyLink() {
      const clipboard = new Clipboard('.copy-link-button', {
        text: () => this.link
      });
      clipboard.on('success', () => {
        console.log('Link copied to clipboard');
        clipboard.destroy(); 
      });

      clipboard.on('error', () => {
        console.error('Failed to copy link to clipboard');
        clipboard.destroy(); 
      });

      this.$refs.copyLinkButton.click();
    }
  }
}
</script>